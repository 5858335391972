@import url("https://fonts.googleapis.com/css2?family=Oxygen:wght@300;700&family=Ruda:wght@400;700;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Ruda", sans-serif;
}
* {
  scroll-behavior: smooth;
}
h1,
.h1 {
  font-size: 70px;
  font-family: Ruda;
  font-weight: 700;
}
h2 {
  color: #fff;
  font-size: 36px;
  font-family: Ruda;
  font-weight: 700;
}
h3 {
  color: #fff;
  font-size: 24px;
  font-family: Ruda;
  font-weight: 700;
}

h4 {
  color: #fff;
  font-size: 20px;
  font-family: Oxygen;
  font-weight: 700;
}
::-webkit-scrollbar {
  width: 10px;
}

h5 {
  color: #fff;
  font-size: 20px;
  font-family: Oxygen;
  font-weight: 300;
}
h6 {
  color: #fff;
  font-size: 16px;
  font-family: Oxygen;
  font-weight: 700;
}

p,
.p {
  font-size: 16px;
  font-family: Oxygen;
  color: #fff;
  font-weight: 300;
}
.height-0,
:where(.collapse > input[type="radio"]) {
  min-height: 0;
}
.collapse-content{
  padding-bottom: 0 !important;
}

.design {
  font-family: "Ruda", Helvetica, Arial, Lucida, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  color: RGBA(255, 255, 255, 0);
  -webkit-text-stroke: 0.600074px #ffff;
  opacity: 0.2;
}
.development {
  font-size: 80px;
  font-family: Ruda;
  font-weight: 900;
  opacity: 0.20000000298023224;
}
.sub_heading {
  font-size: 50px;
  font-family: Ruda;
  font-weight: 900;
}
.main_contaner {
  @apply max-w-[1340px] mx-auto px-5 xl:px-7;
}
.nav_item {
  @apply text-troo-white font-ruda font-normal xl:mr-9 mr-4  relative flex text-center capitalize;
}
.active{
  @apply rounded-sm bg-troo-primary text-troo-secondary
}
.nav_link {
  @apply flex items-center;
}

/* button */
.btn-theme-secondary {
  color: #fff;
  font-family: Ruda;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 1px;
  text-transform: capitalize;
  padding: 10px 20px;
  border-radius: 5px;
  border: 2px solid #fff;
  position: relative;
  z-index: 1;
  transition: all 0.3s ease 0s;
  display: inline-block;
}
.btn-theme-secondary:focus {
  color: #fff;
}
.btn-theme-secondary:hover {
  color: #000;
}

.btn-theme {
  @apply bg-troo-deepblack text-troo-white group-hover:bg-gradient-to-b from-troo-secondary to-troo-tertiary hover:bg-troo-secondary hover:text-troo-deepblack  transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-95  duration-300;
}
.btn-theme-secondary:before {
  content: "";
  background: linear-gradient(180deg, #79f1a6 0%, #4bd2c7 100%);
  height: 10px;
  width: 10px;
  border-radius: 0px;
  position: absolute;
  top: 3px;
  right: 3px;
  z-index: -1;
  transition: all 0.3s ease 0s;
}
.btn-theme-secondary:hover:before {
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
}

.dropdown:hover .dropdown-menu {
  display: flex;
}
@media only screen and (max-width: 767px) {
  .btn {
    margin-bottom: 30px;
  }
}

/* banner */

.para {
  @apply font-oxygen text-troo-white font-[300] text-[24px];
}
.textGradient{
  @apply bg-gradient-to-b from-troo-secondary to-troo-tertiary bg-clip-text text-transparent
}
.bannerBG {
  background-image: url(./Images/bannerBg.png);
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  background-position: center;
}
.bannerGlobalBg {
  background-image: url(./Images/bannerGlobalBg.png);
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  background-position: center;
}
/* service */
.headingMini{
  @apply relative inline-block pr-28  md:pr-40 after:content-[''] after:absolute after:right-0 after:top-[50%] after:translate-y-[-50%] after:w-24 md:after:w-36 after:h-[1px] after:bg-troo-secondary mb-6
}
.viewDetails {
  font-size: 20px;
  font-family: Ruda;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #fff;
  position: relative;
}
.viewDetails::after {
  content: "";
  background-image: url("./Images/arrow.svg");
  position: absolute;
  background-repeat: no-repeat;
  width: 25px;
  height: 20px;
  transform: rotate(-30deg);
  margin-left: 15px;
}

.viewDetails::before {
  content: "";
  position: absolute;
  width: 40px;
  height: 40px;
  background: linear-gradient(180deg, #79f1a6 0%, #4bd2c7 100%);
  opacity: 0.1;
  border-radius: 50%;
  right: 50%;
  top: 50%;
  transform: translate(252%, -50%);
}
.service_card:hover .viewDetails::after {
  transform: rotate(0deg);
  transition: all 0.5s ease;
}
.service_card:hover {
  border-bottom-color: #141414;
  background: #141414;
  box-shadow: 0px 3px 23px 0px rgba(24, 116, 88, 0.25);
}
.service_card {
  transition: all 0.5s ease;
}
.workJourney .dateBoxright {
  position: relative;
  transition: all 0.5s ease;
}
.workJourney .dateBoxright::before {
  content: "";
  position: absolute;
  height: 20px;
  width: 20px;
  transform: translateY(-50%) rotate(45deg);
  top: 50%;
  left: -10px;
  border-left: 1px solid #79f1a610;
  border-bottom: 1px solid #79f1a610;
  transition: all 0.5s ease;
}
.workJourney .dateBoxright:hover::before {
  border-left: 1px solid #79f1a6;
  border-bottom: 1px solid #79f1a6;
  transition: all 0.5s ease;
}
.workJourney .dateBoxleft {
  position: relative;
  transition: all 0.5s ease;
}
.workJourney .dateBoxleft::before {
  content: "";
  background: linear-gradient(180deg, var(--color1) 0%, var(--color2) 100%);
  position: absolute;
  height: 20px;
  width: 20px;
  transform: translateY(-50%) rotate(45deg);
  top: 50%;
  right: -10px;
  border-right: 1px solid #79f1a610;
  border-top: 1px solid #79f1a610;
  transition: all 0.5s ease;
}

.workJourney .dateBoxleft:hover::before {
  border-right: 1px solid #79f1a6;
  border-top: 1px solid #79f1a6;
  transition: all 0.5s ease;
}

/* counter */
.counter_box {
  position: relative;
  transition: all 0.5s ease;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.counter_box::before {
  content: "";
  position: absolute;
  width: 200px;
  height: 200px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: transparent;
  border-radius: 50%;
  transition: all 0.5s ease;
}
.counter_box:hover:before {
  background: #161616;
  box-shadow: 0px 5px 30px 4px rgba(50, 93, 88, 0.25);
  z-index: -1;
}

/* highlight */
.highlight {
  position: relative;
  background-color: #161616;
  overflow: hidden;
}
.highlight::before {
  content: "";
  position: absolute;
  left: 0px;
  right: 0;
  top: -810px;
  height: 900px;
  width: 900px;
  border-radius: 100%;
  background: linear-gradient(180deg, #79f1a6 0%, #4bd2c7 100%);
  opacity: 0.1;
}
.highlight h3 {
  position: relative;
}
.highlight h3:hover .viewDetails::after {
  transform: rotate(0deg);
  transition: all 0.5s ease;
}
.highlight h3::after {
  content: "";
  position: absolute;
  width: 140px;
  height: 140px;
  left: 50%;
  top: 50%;
  transform: translate(-100%, -50%);
  background: #d9d9d9;
  opacity: 0.1;
  border-radius: 100%;
}
/* pricing card */
.pricingCard .card_price {
  font-size: 60px;
  font-family: Ruda;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-left: 15px;
}
.pricingCard .card_dollar {
  font-size: 24px;
  font-family: Ruda;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.pricingCard .doller {
  position: absolute;
  top: 3px;
  left: 0;
}
.pricingCard .btn-theme-secondary {
  display: block;
  text-align: center;
}

.teamBox {
  position: relative;
  transition: all 0.5s ease;
  cursor: pointer;
}

.teamBox::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #141414;
  opacity: 0;
  /* z-index: 1; */
}
.teamBox:hover.teamBox::before {
  opacity: 0.5;
  transition: all 0.5s;
}
.teamicon {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  width: 32px;
  height: 32px;
  color: #fff;
  background-color: #141414;
  transition: all 0.7s ease-in-out 0s;
  cursor: pointer;
  transform: scale(0);
}
.BlogDetails .teamicon{
  transform: scale(1);
}
.team_section .teamicon{
  z-index: 99;
}
.teamBox .teamicon {
  opacity: 0;
}
.teamicon:hover {
  transition: all 0.5s;
  color: #141414;
  background: linear-gradient(180deg, #79f1a6 0%, #4bd2c7 100%);
}
.teamBox:hover .teamicon {
  opacity: 1;
  transition: all 0.5s;
  transform: scale(1);
}
.teamBox:hover h3 {
  color: #79f1a6;
}

.teamBox::after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 0;
  position: absolute;
  top: 0px;
  left: 0px;
  box-shadow: #79f1a6 0px 0px 0px 0px inset;
  opacity: 1;
  transition: all 1s ease-in-out 0s;
  visibility: visible;
}
/* contact */
input {
  font-size: 16px;
  font-family: Ruda;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #fff;
}
/* footer */
.footerBg {
  background-image: url(./Images/footerBg.png);
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  background-position: center;
}
/* Service Card Grid */
.card_grid_arrow {
  display: flex;
  width: 50px;
  height: 50px;
  border: 1px solid;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  bottom: -20px;
  transform: translateX(-50%) scale(0);
  transition: all 0.5s ease-in-out 0s;
  opacity: 0;
  background-color: #79f1a6;
  align-items: center;
  justify-content: center;
}
.service_card:hover .card_grid_arrow {
  opacity: 1;
  transform: translateX(-50%) scale(1);
}

/* Error page */
.Error404 {
  background-image: url(./Images/errorBg.png);
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}
.error {
  text-align: center;
  font-family: Ruda;
  font-size: 300px;
  font-style: normal;
  font-weight: 700;
}
/* Coming soon */
.commingsoon {
  background-image: url(./Images/comingsoonBg.png);
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  background-position: inherit;
  background-size: cover;
}
.hello {
  font-family: Oxygen;
  font-size: 36px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  color: #fff;
}
.comingHeading {
  max-width: 730px;
  font-family: Oxygen;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #fff;
}

@media (max-width: 1024px) {
  .counter_box::before {
    width: 160px;
    height: 160px;
  }
  h1 {
    font-size: 45px;
  }
  .h1{
    font-size: 45px;
  }
  h2 {
    font-size: 28px;
  }
  h3 {
    font-size: 20px;
  }
  h4 {
    font-size: 18px;
  }
  p {
    font-size: 14px;
  }
  .pricingCard .card_price {
    font-size: 32px;
  }
  .hello{
    font-size: 26px;
  }
  .comingHeading{
    font-size: 32px;
  }
}
@media (max-width : 767px){
  .highlight h3::after{
    display: none;
  }
  .sub_heading{
    font-size: 32px;
  }
  .h1{
    font-size: 36px;
  }
}
